import axios from '@/axios';

class TranslationService {
    index(query) {
        return axios.get(`/settings/translations`, {
            params: query
        });
    }
    update(data) {
        return axios.put(`/settings/translations`, data);
    }
}

export const translationService = new TranslationService();