<template>
    <div class="table-view users-table-view">
        <div class="action-header">
            <h1>{{ $t('Translations') }}</h1>
            <div class="actions">
                <button type="button" class="btn btn-grey" @click="getTranslations">
                    {{ $t('Refresh') }}
                    <svg xmlns="http://www.w3.org/2000/svg" width="20.948" height="20" viewBox="0 0 20.948 20">
                        <g>
                            <g>
                                <path d="M10 13a8.37 8.37 0 0 1 7.528 4.47 3 3 0 0 1-2.58 4.53h-9.9a3 3 0 0 1-2.581-4.53A8.37 8.37 0 0 1 10 13zm0 2a6.383 6.383 0 0 0-5.808 3.49 1 1 0 0 0 .86 1.51h9.9a1 1 0 0 0 .86-1.51A6.383 6.383 0 0 0 10 15zm9-7a1 1 0 0 1 .993.883L20 9v2h2a1 1 0 0 1 .117 1.993L22 13h-2v2a1 1 0 0 1-1.993.117L18 15v-2h-2a1 1 0 0 1-.117-1.993L16 11h2V9a1 1 0 0 1 1-1zm-8.833-6a5 5 0 0 1 0 10H10a5 5 0 0 1 0-10h.167zm-.083 2h.083a3 3 0 0 0-.083 6H10a3 3 0 0 0 .083-6z" transform="translate(-820.052 268) translate(818 -270)"/>
                            </g>
                        </g>
                    </svg>
                </button>
            </div>
        </div>
        <table class="table" v-for="translation in translations" :key="translation.id">
            <thead>
                <tr>
                    <th class="small-col" scope="col">{{ translation.key }}</th>
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
                <tr class="align-cols-top" v-for="language in languages" :key="language">
                    <td class="small-col">{{ language.toUpperCase() }}</td>
                    <td>
                        <textarea @change="updateTranslation(language, translation.key, $event.target.value)" :value="translation.text[language] ? translation.text[language] : ''" :placeholder="$t('Enter translation')" class="form-control mb-0"></textarea>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import HeaderSearch from '@/mixins/HeaderSearch'
import {translationService} from '@/services/settings/translationService'

export default {
    name: "ViewAllEvents",
    mixins: [ HeaderSearch ],
    data() {
        return {
            translations: [],

            languages: [
                "en",
                "nl",
                "lv",
                "ru"
            ],
            search: '',
            page: '',
            showAddComponent: false,
        }
    },
    mounted() {
        this.getTranslations();
    },
    methods: {
        searchFromHeader(keyword) {
            this.search = keyword;
            this.getTranslations();
        },
        getTranslations() {
            translationService.index({
                "q": this.search
            }).then(response => {
                this.translations = response.data.data
            })
        },
        updateTranslation(language, key, text) {
            translationService.update({
                'key': key,
                'lang': language,
                'value': text
            }).then(response => {
                this.getTranslations();
            })
        }
    }
}
</script>